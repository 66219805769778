import React from 'react';

function App() {
  return (
    <div>
      <h1>Welcome to amyskochdopole.com!</h1>
      {/* Add more React components and content here */}
    </div>
  );
}

export default App;

